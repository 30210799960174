<template>
  <div v-if="ready">
    <div class="card card-custom gutter-b card-nobody card-sticky">
      <div class="card-header">
        <div class="card-title div-center">
          <h3 class="card-label">Fiche lead #{{lead.id}}</h3>
        </div>
        <div class="card-title div-center">
          <b-button block :variant="LeadStatus.color" size="lg" style="font-size:1.20em">{{LeadStatus.titre.fr}}</b-button>
        </div>
        <div class="card-toolbar div-center">
          <b-dropdown v-if="lead.status_id != 4 && create === false" v-b-tooltip.hover :title="$store.back.getText('modif_status')" class="mr-1" size="sm" variant="dark" html='<i class="fas fa-thermometer-three-quarters"></i>'>
            <b-dropdown-item v-for="l in params.LeadStatus" :key="l.id" @click="changeStatus(l.id)" :active="l.id === lead.status_id">{{l.titre.fr}}</b-dropdown-item>
          </b-dropdown>
          <b-dropdown v-b-tooltip.hover :title="$store.back.getText('change_user_lead')" class="mr-1" size="sm" variant="primary" html='<i class="fas fa-user"></i>'>
            <b-dropdown-item v-for="u in params.User" :key="u.id" @click="lead.user_id = u.id" :active="u.id === lead.user_id">{{u.prenom}} {{u.nom[0]}}</b-dropdown-item>
          </b-dropdown>
          <b-button v-b-tooltip.hover :title="$store.back.getText('carte_btn')" variant="secondary" size="sm" class="btn-icon mr-1" @click="openMap"><i class="icon-md fas fa-map"></i></b-button>
          <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_rdv')" variant="info" size="sm" class="btn-icon mr-1" v-b-modal.calendar><i class="icon-md fas fa-calendar"></i></b-button>
          <a v-b-tooltip.hover :title="$store.back.getText('save')" class="btn btn-icon btn-sm btn-success mr-1" @click="save" v-if="$store.getters.currentUser.role === 1">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3 card-sticky" v-if="map">
        <b-row>
          <b-col md="4">
            <gmap-street-view-panorama  class="pano" :position="waypoints[0]" :zoom="1" style="height:350px"></gmap-street-view-panorama>
          </b-col>
          <b-col md="4">
            <Map :waypoints="waypoints" />
          </b-col>
          <b-col md="4">
            <gmap-street-view-panorama  class="pano" :position="waypoints[1]" :zoom="1" style="height:350px"></gmap-street-view-panorama>
          </b-col>
        </b-row>
      </div>
    <div class="card-deck">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('info-client')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('last_name')+':'">
                <b-form-input v-model="lead.nom" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('first_name')+':'">
                <b-form-input v-model="lead.prenom" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('phone')+':'">
                <b-form-input v-model="lead.telephone" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('mobile')+':'">
                <b-form-input v-model="lead.mobile" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('email')+':'">
                <b-form-input v-model="lead.email" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('type')+':'">
                <select class="form-control"  v-model="lead.type_id">
                  <option v-for="(t,i) in params.LeadType" :key="i" :value="t.id">{{t.titre.fr}}</option>
                </select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('volume')+' m3:'">
                <b-form-input v-model="lead.volume" @focusout="setVolume()" required></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="this.$store.back.getText('distance')+' km:'">
                <b-form-input v-model.number="lead.distance" readonly></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">Commentaire</h3>
          </div>
        </div>
        <div class="card-body">
          <b-form-group :label="this.$store.back.getText('commentaire')+':'" >
            <b-form-textarea v-model="lead.commentaire" rows="4" readonly></b-form-textarea>
          </b-form-group>
          <b-form-group :label="this.$store.back.getText('ajouter')+':'">
            <b-input-group>
              <b-form-input v-model="commentaireTmp" type="text" required placeholder="Entre votre commentaire"></b-form-input>
              <template #append>
                <b-button v-b-tooltip.hover :title="$store.back.getText('ajouter_commentaire')" variant="success" class="btn-icon mr-1" @click="addCommentaire()"><i class="icon-md fas fa-plus"></i></b-button>
              </template>
            </b-input-group>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="card-deck mt-3">
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('info')}} {{$store.back.getText('chargement')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-col md="6">
            <b-form-group :label="this.$store.back.getText('date')+':'">
              <b-form-datepicker v-model="lead.date_chargement"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <AutoComplete v-model="lead" type="depart"/>
        </div>
      </div>
      <div class="card card-custom gutter-b">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('info')}} {{$store.back.getText('livraison')}}</h3>
          </div>
        </div>
        <div class="card-body">
          <b-col md="6">
            <b-form-group :label="this.$store.back.getText('date')+':'">
              <b-form-datepicker v-model="lead.date_livraison"></b-form-datepicker>
            </b-form-group>
          </b-col>
          <AutoComplete v-model="lead" type="destination"/>
        </div>
      </div>
    </div>
    <div class="card card-custom gutter-b mt-3" v-if="devis.length > 0">
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">{{$store.back.getText('list_devis')}}</h3>
          </div>
          <div class="card-toolbar">
            <a class="btn btn-icon btn-sm btn-success mr-1" v-b-tooltip.hover :title="$store.back.getText('create_deivs')" @click="save(true)">
              <i class="icon-md fas fa-plus"></i>
            </a>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Ref</th>
                <th scope="col">{{$store.back.getText('cp_depart')}}</th>
                <th scope="col">{{$store.back.getText('cp_livraison')}}</th>
                <th scope="col">{{$store.back.getText('categorie')}}</th>
                <th scope="col">{{$store.back.getText('total-ht')}}</th>
                <th scope="col">Statut</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d,i) in devis" :key="i">
                <td scope="row">
                  <a :href="'/#/devis/detail/'+d.id">#{{d.id}}</a>
                </td>
                <td scope="row">
                  {{d.depart.cp}}
                </td>
                <td scope="row">
                  {{d.destination.cp}}
                </td>
                <td scope="row">
                  {{$store.back.getParamTitre(params.Categorie,d.categorie_id)}}
                </td>
                <td scope="row">
                  {{d.total_ht}}
                </td>
                <td scope="row">
                  {{$store.back.getParamTitre(params.DevisStatus,d.status_id)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    <b-modal id="calendar" title="Calendrier" ref="calendar">
      <b-form>
        <b-form-group label="Titre:">
          <b-form-input v-model="calendarTmp.name" type="text" required placeholder="Entre le titre"></b-form-input>
        </b-form-group>
        <b-form-group label="Detail:">
          <b-form-input v-model="calendarTmp.detail" type="text" required placeholder="Entre le deail"></b-form-input>
        </b-form-group>
        <b-form-group label="Debut:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart"></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeStart"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group label="Fin:">
          <b-row>
            <b-col size="md-6">
              <b-form-datepicker v-model="calDateStart" readonly></b-form-datepicker>
            </b-col>
            <b-col size="md-6">
              <b-form-timepicker v-model="calTimeEnd"></b-form-timepicker>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right mr-2" @click="saveCal()">
            Sauvegarder
          </b-button>
          <b-button variant="danger" class="float-right mr-2" @click="$refs.calendar.hide();">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Map from '@/components/Map'
import AutoComplete from '@/components/AutoComplete';

export default {
  data(){
    return {
      ready:false,
      map:false,
      create:true,
      calDateStart:null,
      calTimeStart:"12:00",
      calTimeEnd:"12:30",
      devis:[],
      calendarTmp:{
        name: null,
        start: null,
        end: null,
        detail: null,
        url:`/${location.hash}`
      },
      commentaireTmp:null,
      lead: this.$store.back.getSchema('Lead'),
      params:{
        LeadType:[],
        LeadStatus:[],
        status:[],
        User:[],
        Categorie:[],
        DevisStatus:[]
      }
    };
  },
  components: {AutoComplete, Map},
  watch: {
    calDateStart(){
      this.calendarTmp.start = this.calDateStart+' '+this.calTimeStart;
      this.calendarTmp.end = this.calDateStart+' '+this.calTimeEnd;
    },
    'lead.depart.lon'(){
      this.getDistance();
    },
    'lead.destination.lon'(){
      this.getDistance();
    }
  },
  methods: {
    save(devis = false) {
      this.$store.back.ajax('/lead/create', this.lead, (res) => {
        if(res.status){
          if(this.lead.id === null){
            this.$router.push({path:'/lead/detail/'+res.data.id});
            setTimeout(() => {location.reload()},500);
          }else{
            this.lead = res.data;
            this.$bvToast.toast(`Lead enregistre !`, {
              title: 'Sauvegarder !',
              variant: 'success',
              solid: true
            });
            if(devis === true){
              var params = {lead_id:this.lead.id}
              this.$store.back.ajax('/devis/create', params, (res) => {
                if(res.status === true){
                  this.$router.push({path:'/devis/detail/'+res.data.id});
                }
              })
            }
          }
        }
      });
    },
    saveCal(){
      this.$store.back.ajax('/calendrier/create', this.calendarTmp, (res) => {
        if(res.status === true){
          this.$bvToast.toast(`Votre rdv a ete sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$refs.calendar.hide();
        }
      });
    },
    addCommentaire(){
      if(this.lead.commentaire === null){
        this.lead.commentaire = "";
      }
      var user = this.$store.back.getUser();
      var date = new Date();
      this.lead.commentaire += `${this.commentaireTmp} | ${user.prenom} ${user.nom} ${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`+"\n";
      this.commentaireTmp = null;
    },
    changeStatus(id){
      if(this.lead.status_id === id){
        return false;
      }
      this.lead.status_id = id;
      if(this.lead.status_id === 4){
        this.save(true)
      }
    },
    openMap(){
      this.map = !this.map;
      if(this.map === false){
        return false;
      }
      if(this.waypoints === false){
        this.map = false;
      }
    },
    getDistance(){
      if(this.lead.depart.lon === null || this.lead.depart.lat === null || this.lead.destination.lon === null || this.lead.destination.lat === null){
        return false;
      }
      var url = `http://maps.get-move.pro/routing/route/driving/${this.lead.depart.lon},${this.lead.depart.lat};${this.lead.destination.lon},${this.lead.destination.lat}?overview=false&alternatives=true&steps=true&hints=;`;
      fetch(url).then( response => {
        response.json().then( data =>{
          if(data.routes[0].distance != undefined){
            this.lead.distance = Math.floor(data.routes[0].distance/1000);
          }
        })
      });
    }
  },
  computed: {
    waypoints(){
      if(this.lead.depart.lon === null || this.lead.depart.lon === null){
        this.$bvToast.toast(this.$store.back.getText('merci_renseigner'), {
          title: 'Erreur !',
          variant: 'danger',
          solid: true
        });
        return false
      }
      return [
        { lat: this.lead.depart.lat, lng: this.lead.depart.lon },
        { lat: this.lead.destination.lat, lng: this.lead.destination.lon }
      ];
    },
    LeadStatus(){
      var check = this.params.LeadStatus.find(s => s.id == this.lead.status_id);
      if(check === undefined){
        check = {
          id:1,
          titre:{
            fr:"Disponible"
          },
          color:"primary"
        }
      }
      return check;
    },
  },
  beforeMount(){
    if(this.$route.params.id != undefined){
      this.create = false;
      this.$store.back.ajax(`/lead/${this.$route.params.id}`, null, (res) => {
        if(res.status){
          this.lead = res.data;
        }
      });
    }
    let params = {
      modules: ["LeadType","LeadStatus","Categorie","DevisStatus"]
    };
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.LeadType = res.data['LeadType'];
        this.params.LeadStatus = res.data['LeadStatus'];
        this.params.status = res.data['status'];
        this.params.User = res.data['MyUsers'].users;
        this.params.Categorie = res.data['Categorie'];
        this.params.DevisStatus = res.data['DevisStatus'];
        this.ready = true;
      }
    });
    this.$store.back.ajax(`/devis/lead/${this.$route.params.id}`, null, (res) => {
      if(res.status === true){
        this.devis = res.data;
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
